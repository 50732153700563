import { env } from '../../app/utils';

export type ENV = 'development' | 'staging' | 'production';

interface EnvURLs {
  development?: string;
  staging?: string;
  production?: string;
}

const getEnvURLs = (envHosts: EnvURLs, path: string) => {
  return Object.keys(envHosts).reduce(
    (urls: EnvURLs, env: string) => {
      urls[env as ENV] = `${envHosts[env as ENV]}${path}`;

      return urls;
    },
    { development: '', staging: '', production: '' }
  );
};

export const apiEnvsHost = {
  development: process.env.DEV_API_URL,
  staging: `https://${process.env.HEROKU_API_NAME}.herokuapp.com`,
  production: 'https://www.rentgrata.com',
};

export const clientsEnvsHost = {
  development: `http://localhost:${process.env.WIDGET_PORT}`,
  staging: `https://${process.env.HEROKU_APP_NAME}.herokuapp.com`,
  production: 'https://widget.rentgrata.com',
};

export const libEnvsHost = {
  development: `http://localhost:${process.env.LIB_PORT}`,
  staging: `https://${process.env.HEROKU_APP_NAME}.herokuapp.com`,
  production: 'https://widget.rentgrata.com',
};

export const minifiedLibHost = env.isDevelopment
  ? libEnvsHost
  : clientsEnvsHost;
export const zoidCDNURL =
  'https://cdnjs.cloudflare.com/ajax/libs/zoid/6.0.80/zoid.min.js';
export const optimizeURL = `https://www.googleoptimize.com/optimize.js?id=${process.env.OPTIMIZE_ID}`;
export const apis = getEnvURLs(apiEnvsHost, '/api');
export const messengerURLs = getEnvURLs(clientsEnvsHost, '/messenger.html');
export const featureIconsURLs = getEnvURLs(clientsEnvsHost, '/features.html');
export const compactV2URLs = getEnvURLs(clientsEnvsHost, '/compact.html');
export const analyticsURLs = getEnvURLs(clientsEnvsHost, '/analytics.js');
export const widgetScriptURLs = getEnvURLs(libEnvsHost, '/rentgrata.js');
export const baseAPIURL = apis[process.env.NODE_ENV as ENV];
